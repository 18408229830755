<template>
  <div class="dp-brew-myrecipe">
    <div v-if="!contents || !contents.length" class="dp-brew-myrecipe-empty">
      <div class="dp-brew-myrecipe-empty__section">
        <DpEmpty
          icon-name="dpBookmarkOffR"
          :text="WORDS.DRIP_POD_MYRECIPE_LIST.EMPTY_TEXT"
          :subtext="WORDS.DRIP_POD_MYRECIPE_LIST.EMPTY_SUBTEXT" />
      </div>

      <div class="dp-brew-myrecipe-empty__button">
        <DpButton to="/drip-pod/pro-recipe/" style-type="secondary" fluid>
          {{ WORDS.DRIP_POD_MYRECIPE_LIST.MORE }}
        </DpButton>
      </div>
    </div>

    <template v-else>
      <DpPageLoading v-if="isLoading" :is-loading="isLoading" />

      <div class="dp-brew-myrecipe__inner">
        <DripPodMyrecipeCard
          v-for="content in contents"
          :key="content.id"
          :content="content"
          @on-img-loaded="onCardImgLoaded" />
      </div>
    </template>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    DripPodMyrecipeCard: () =>
      import('@/components/dripPod/brew/MyrecipeCard.vue'),

    DpButton: () => import('@/components/dripPod/core/Button.vue'),
    DpPageLoading: () => import('@/components/dripPod/core/PageLoading.vue'),
    DpEmpty: () => import('@/components/dripPod/core/Empty.vue')
  },

  props: {
    contents: {
      type: Array,
      default: undefined
    }
  },

  setup: (props) => {
    const loadedImgCount = ref(0);
    const isLoading = computed(
      () => loadedImgCount.value !== props.contents.length
    );
    const onCardImgLoaded = () => {
      loadedImgCount.value++;
    };

    return {
      WORDS,
      onCardImgLoaded,
      isLoading
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-brew-myrecipe {
  @include mixin.dpDefaultText;

  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &::after {
      display: block;
      content: '';
      margin-top: -1px;
      width: 100%;
      border-bottom: 1px solid variables.$dpGreyEa;
    }
  }
}

.dp-brew-myrecipe-empty {
  margin: 0 16px;
  padding: 48px 0;

  &__section {
    margin-bottom: 48px;
  }
}
</style>
