var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dp-brew-myrecipe" },
    [
      !_vm.contents || !_vm.contents.length
        ? _c("div", { staticClass: "dp-brew-myrecipe-empty" }, [
            _c(
              "div",
              { staticClass: "dp-brew-myrecipe-empty__section" },
              [
                _c("DpEmpty", {
                  attrs: {
                    "icon-name": "dpBookmarkOffR",
                    text: _vm.WORDS.DRIP_POD_MYRECIPE_LIST.EMPTY_TEXT,
                    subtext: _vm.WORDS.DRIP_POD_MYRECIPE_LIST.EMPTY_SUBTEXT,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "dp-brew-myrecipe-empty__button" },
              [
                _c(
                  "DpButton",
                  {
                    attrs: {
                      to: "/drip-pod/pro-recipe/",
                      "style-type": "secondary",
                      fluid: "",
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.WORDS.DRIP_POD_MYRECIPE_LIST.MORE) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : [
            _vm.isLoading
              ? _c("DpPageLoading", { attrs: { "is-loading": _vm.isLoading } })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "dp-brew-myrecipe__inner" },
              _vm._l(_vm.contents, function (content) {
                return _c("DripPodMyrecipeCard", {
                  key: content.id,
                  attrs: { content: content },
                  on: { "on-img-loaded": _vm.onCardImgLoaded },
                })
              }),
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }